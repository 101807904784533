@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import 'primereact/resources/themes/nova/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';

/* Tailwind styles */
/* @layer base {
	body, h1, h2, h3, p {
	  @apply m-0 p-0;
	}
  
	body {
	  @apply font-sans;
	}
  
	a {
	  @apply text-blue-400 no-underline;
	}
  
	a:hover {
	  @apply text-blue-500;
	}
} */





